import React from 'react';
import { motion } from 'framer-motion';
import featurepic1 from '../Assets/Images/soil-secret-2-1.svg';
import featurepic2 from '../Assets/Images/soil-secret-2-2.svg';
import featurepic3 from '../Assets/Images/soil-secret-3-1.svg';

const FeatureCard = ({ icon, title, description, color }) => (
  <motion.div 
    className={`bg-white rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-all duration-300 hover:shadow-xl border-b-4 ${color}`}
    whileHover={{ y: -5 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="mb-4 p-3 bg-emerald-100 rounded-full w-24 h-24 flex items-center justify-center">
      <img src={icon} alt={title} className="w-16 h-16 object-contain" />
    </div>
    <h3 className="text-xl font-bold text-[rgb(2,58,16)] mb-2">{title}</h3>
    <p className="text-gray-800 font-serif">{description}</p>
  </motion.div>
);

const Feature = () => {
  const features = [
    {
      icon: featurepic1,
      title: "Access Farm Health Insights",
      description: "Gain valuable insights into your farm's health with our Phygital Network powered by Kiaan.",
      color: "border-blue-500"
    },
    {
      icon: featurepic2,
      title: "Precision Farming",
      description: "Grow your crops with unparalleled precision by using Kiaan's advanced farming solutions.",
      color: "border-blue-500"
    },
    {
      icon: featurepic3,
      title: "AI-Powered Recommendations",
      description: "Leverage AI-powered recommendations and expert advice for informed decision-making.",
      color: "border-blue-500"
    }
  ];

  return (
    <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-tr from-green-100 to-green-300 relative overflow-hidden">
      <div className="absolute inset-0 bg-opacity-50 bg-grid-pattern"></div>
      <div className="container mx-auto relative">
        <motion.div 
          className="text-center mb-12"
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <h2 className="text-4xl font-bold text-[rgb(2,58,16)] mb-4">Elevate Your Agriculture with Technology</h2>
          <p className="text-xl text-gray-800 max-w-2xl mx-auto font-serif">Harness the power of technology to transform your agricultural practices and boost productivity.</p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
            >
              <FeatureCard {...feature} />
            </motion.div>
          ))}
        </div>
      </div>
      <div className="absolute -bottom-16 -left-16 w-64 h-64 bg-gradient-to-br from-emerald-300 to-emerald-500 rounded-full opacity-20 blur-3xl"></div>
      <div className="absolute -top-16 -right-16 w-64 h-64 bg-gradient-to-br from-blue-300 to-blue-500 rounded-full opacity-20 blur-3xl"></div>
    </section>
  );
};

export default Feature;