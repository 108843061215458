import React, { useState, useEffect, useRef } from 'react';
import Smallleafimage from '../Assets/Images/smallleaf_pic.jpg';
import KiaanButton from './KiaanButton';
import videoSrc from '../Assets/Videos/Drone_Video.mp4';

const Story = () => {
    const [typedStory, setTypedStory] = useState(''); // For typing effect
    const [isTyping, setIsTyping] = useState(true);   // Track if text is typing
    const fullStory = "For centuries, agriculture has operated on traditional practices, lacking data and scientific efficiency.";
    const sectionRef = useRef(null); 
    const videoRef = useRef(null);  // Ref to video

    useEffect(() => {
        let storyIndex = 0;
        let typingTimeout;

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,  // Trigger when 20% of the section is in view
        };

        const handleIntersection = (entries) => {
            const [entry] = entries;
            setIsTyping(entry.isIntersecting);
            if (entry.isIntersecting && videoRef.current) {
                videoRef.current.play();
            } else if (videoRef.current) {
                videoRef.current.pause();
            }
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        const typeText = () => {
            if (storyIndex < fullStory.length) {
                setTypedStory(fullStory.slice(0, storyIndex + 1));
                storyIndex++;
                typingTimeout = setTimeout(typeText, 30); // Adjust speed as needed
            }
        };

        if (isTyping) {
            typeText();
        }

        return () => {
            clearTimeout(typingTimeout);
            observer.disconnect();  // Cleanup observer
        };
    }, [isTyping]);

    return (
        <section
            ref={sectionRef}
            className="relative py-12 md:py-20 bg-gradient-to-br from-green-100 to-green-300 overflow-hidden"
        >
            <div className="container mx-auto px-4" id='about'>
                <div className="flex flex-col lg:flex-row items-center">
                    {/* Left side image */}
                    <div className={`lg:w-1/2 mb-8 lg:mb-0 animateFadeInLeft`}>
                        <div className="relative">
                            <video
                                ref={videoRef}
                                className="w-full rounded-lg shadow-lg transition-transform duration-700 ease-in-out transform 
                                            hover:scale-105 hover:shadow-2xl"
                                loop
                                muted
                                playsInline
                                src={videoSrc}
                                onMouseEnter={() => videoRef.current.play()}
                                onMouseLeave={() => videoRef.current.pause()}
                            >
                                Your browser does not support the video tag.
                            </video>
                            <div className="absolute inset-0 rounded-lg 
                                           transition-opacity duration-500 ease-in-out group-hover:bg-opacity-20">
                            </div>
                            <div className="absolute right-2 top-1 max-w-[100px] lg:max-w-[150px] md:max-w-[130px] border-white rounded shadow-lg animate-movebounce3 md:right-3">
                                <img src={Smallleafimage} alt="Small leaf" className="w-full border-4 rounded-lg" />
                            </div>
                        </div>
                    </div>

                    {/* Right side story text */}
                    <div className={`lg:w-1/2 lg:pl-6 pl-2 animateFadeInRight`}>
                        <div className="text-left">
                            <h2 className="text-4xl text-[rgb(2,58,16)] mb-4 md:mb-2 font-bold">Our Story</h2>
                            <h3 className="text-2xl mb-4 text-green-700 font-medium">{typedStory}</h3>
                        </div>
                        <p className="font-serif text-lg mb-6 text-gray-800">
                            Kiaan Agrowtech uses data and analytics to revolutionize farming at every stage,
                            integrating advanced technologies to enhance crop monitoring, automate farm management,
                            and optimize resource usage.
                        </p>
                        <div>
                            <KiaanButton href="#whykiaan">
                                <span className="relative z-10 font-semibold text-green-950">About More</span>
                            </KiaanButton>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Story;

// import React, { useState, useEffect, useRef } from 'react';
// import Smallleafimage from '../Assets/Images/smallleaf_pic.jpg';
// import KiaanButton from './KiaanButton';
// import videoSrc from '../Assets/Videos/Drone_Video.mp4';

// const Story = () => {
//     const [typedStory, setTypedStory] = useState('');
//     const fullStory = "Farming has evolved from traditional practices to data-driven innovation, nurturing both land and life.";
//     const videoRef = useRef(null);

//     useEffect(() => {
//         let storyIndex = 0;
//         let typingTimeout;

//         const typeText = () => {
//             if (storyIndex < fullStory.length) {
//                 setTypedStory(fullStory.slice(0, storyIndex + 1));
//                 storyIndex++;
//                 typingTimeout = setTimeout(typeText, 40);
//             }
//         };

//         typeText();

//         return () => clearTimeout(typingTimeout);
//     }, []);

//     return (
//         <section className="relative flex flex-col items-center py-16 overflow-hidden bg-gradient-to-br from-gray-50 to-gray-200">
//             {/* Background Video */}
//             <div className="absolute inset-0 w-full h-full overflow-hidden opacity-40">
//                 <video
//                     ref={videoRef}
//                     className="w-full h-full object-cover"
//                     src={videoSrc}
//                     loop
//                     muted
//                     playsInline
//                     autoPlay
//                 />
//                 <div className="absolute inset-0 bg-gradient-to-r from-green-300 via-white to-green-300 opacity-20"></div>
//             </div>

//             {/* Content */}
//             <div className="relative z-10 container mx-auto px-6 text-center md:text-left md:max-w-2xl">
//                 <h2 className="text-3xl md:text-4xl font-semibold text-green-800 mb-3">
//                     Our Humble Beginnings
//                 </h2>
//                 <p className="text-lg md:text-xl text-gray-700 italic font-light mb-6">{typedStory}</p>
//                 <p className="text-base text-gray-600 mb-8">
//                     At Kiaan Agrowtech, we blend the wisdom of nature with the precision of data, creating a balanced approach to sustainable farming. 
//                     Through careful data analysis, we aim to reduce waste and enhance productivity, paving the way for a greener tomorrow.
//                 </p>
//                 <KiaanButton href="#whykiaan">
//                     <span className="font-semibold text-green-700">Discover Our Vision</span>
//                 </KiaanButton>
//             </div>

//             {/* Leaf Image */}
//             <div className="absolute bottom-4 right-4 w-20 md:w-28 opacity-90">
//                 <img src={Smallleafimage} alt="Small leaf icon" className="rounded-full border border-gray-200 shadow-md" />
//             </div>
//         </section>
//     );
// };

// export default Story;
