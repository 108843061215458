import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import projectpic1 from '../Assets/Images/project1.jpeg';
import projectpic2 from '../Assets/Images/project2.jpeg';
import projectpic3 from '../Assets/Images/project3.jpeg';
import projectpic4 from '../Assets/Images/project4.JPG';
import projectpic5 from '../Assets/Images/project5.jpg';
import projectpic6 from '../Assets/Images/project6.png';

const images = [
  {
    src: projectpic1,
    alt: "FarmLand",
    title: "BNGAGRBN01",
    description: "3 Acres Of Yellaki Banana, 1 Acre Of G9 Banana, 1 Acre Of Tomato, 1 Acre Of Chilli.",
    gallery: '/gallery/BNGAGRBN01-gallery'
  },
  {
    src: projectpic2,
    alt: "FarmLand",
    title: "MYSCHMBN02",
    description: "10 Acres Of Yellaki Banana, 10 Acres Of Chilli.",
    gallery: '/gallery/MYSCHMBN02-gallery'
  },
  {
    src: projectpic3,
    alt: "FarmLand",
    title: "MYSAVGBN03",
    description: "10 Acres Of Yellaki Banana, 5 Acres Of G9 Banana, 10 Acres Of Watermelon.",
    gallery: '/gallery/MYSAVGBN03-gallery'
  },
  {
    src: projectpic4,
    alt: "FarmLand",
    title: "MYSCHMBN04",
    description: "23 Acres Of Yellaki Banana.",
    gallery: '/gallery/MYSCHMBN04-gallery'
  },
  {
    src: projectpic5,
    alt: "FarmLand",
    title: "MYSKTGBN05",
    description: "18 Acres Of G9 Banana.",
    gallery: '/gallery/MYSKTGBN05-gallery'
  },
  {
    src: projectpic6,
    alt: "FarmLand",
    title: "MYSGDHBN06",
    description: "19 Acres Of Yellaki & G9 Banana.",
    gallery: '/gallery/MYSGDHBN06-gallery'
  }
];

const Projects = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const navigate = useNavigate();

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleExploreGallery = () => {
    const galleryPath = images[currentIndex].gallery;
    navigate(galleryPath);
  };

  // Touch event handlers
  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) {
      handleNext();
    }
    if (isRightSwipe) {
      handlePrev();
    }
  };

  return (
    <div className="bg-gradient-to-br from-green-100 to-green-300 flex items-center justify-center min-h-screen p-4 md:p-6" id="projects">
      <div className="max-w-7xl w-full">
        <h2 className="text-4xl -mt-8 font-bold text-green-900 flex text-center justify-center mb-20">OUR PROJECTS</h2>

        <div className="relative overflow-hidden rounded-2xl shadow-2xl">
          <div 
            className="h-[400px] md:h-[600px]"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
          >
            {images.map((image, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${index === currentIndex ? 'opacity-100 z-10' : 'opacity-0 z-0'}`}
              >
                <img src={image.src} alt={image.alt} className="h-full w-full object-cover object-center" />
                <div className="absolute bottom-0 left-0 right-0 p-4 md:p-6 bg-black bg-opacity-50 text-white">
                  <h2 className="text-2xl md:text-3xl font-bold">{image.title}</h2>
                  <p className="text-sm md:text-lg mt-2 font-serif">{image.description}</p>
                  <button onClick={handleExploreGallery} className="mt-4 bg-green-600 hover:bg-green-800 text-white py-2 px-4 rounded">
                    Explore Project Gallery
                  </button>
                </div>
              </div>
            ))}
          </div>

          {!isMobile && (
            <>
              <button className="absolute top-1/2 left-2 md:left-4 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-50 transition-opacity duration-300 rounded-full p-1 md:p-2 focus:outline-none z-20" onClick={handlePrev}>
                <ChevronLeft className="w-4 h-4 md:w-6 md:h-6 text-white" />
              </button>
              <button className="absolute top-1/2 right-2 md:right-4 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-50 transition-opacity duration-300 rounded-full p-1 md:p-2 focus:outline-none z-20" onClick={handleNext}>
                <ChevronRight className="w-4 h-4 md:w-6 md:h-6 text-white" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;