import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import {  ShoppingCart, UserCheck, Tractor } from 'lucide-react';
import Kiaanecompic from '../Assets/Images/Ecomplatform.jpeg';


const TypeWriter = ({ text, delay = 40, isVisible }) => {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (isVisible && index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText((prev) => prev + text.charAt(index));
        setIndex(index + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [index, text, delay, isVisible]);

  return <span>{displayText}</span>;
};

const FeatureCard = ({ title, description, icon: Icon }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-50px 0px" });

  return (
    <motion.div
      ref={ref}
      className="bg-green-700/20 backdrop-blur-md rounded-lg p-6 flex flex-col items-center text-center transition-all duration-300 hover:bg-green-700/40"
      whileHover={{ scale: 1.05, boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)" }}
      initial={{ opacity: 0, y: 30 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <motion.div
        whileHover={{ rotate: 360, scale: 1.2 }}
        transition={{ duration: 0.5 }}
      >
        <Icon className="w-12 h-12 text-green-600 mb-4" />
      </motion.div>
      <h3 className="text-xl font-semibold mb-2 text-black">{title}</h3>
      <p className="text-green-950 font-serif">{description}</p>
    </motion.div>
  );
};

const EcomSoftwarePromo = () => {
  const controls = useAnimation();
  const [isTypingVisible, setIsTypingVisible] = useState(false);
  const typewriterRef = useRef(null);
  const imageRef = useRef(null);
  const isImageInView = useInView(imageRef, { once: true, margin: "-100px 0px" });

  

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsTypingVisible(true);
          } else {
            setIsTypingVisible(false);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (typewriterRef.current) {
      observer.observe(typewriterRef.current);
    }

    return () => {
      if (typewriterRef.current) {
        observer.unobserve(typewriterRef.current);
      }
    };
  }, []);

  return (
    <motion.div 
      className="relative min-h-screen overflow-hidden text-black"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div 
        className="absolute inset-0 bg-gradient-to-tr from-green-100 to-green-300"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        id="ecom"
      />

      <div className="relative z-10 container mx-auto px-4 py-20">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <motion.h1 
            className="text-4xl lg:text-6xl font-bold mb-6 text-green-900"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8, type: "spring", stiffness: 100 }}
          >
            Your Trusted Marketplace for Smarter Farming Solutions
          </motion.h1>
          <p
            className="text-xl md:text-2xl mb-8 text-black font-serif"
            ref={typewriterRef}
          >
            <TypeWriter
              text="Empowering Agriculture, Delivering Excellence"
              delay={20}
              isVisible={isTypingVisible}
            />
          </p>
        </motion.div>

        <motion.div
          ref={imageRef}
          initial={{ opacity: 0, y: 50 }}
          animate={isImageInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          className="relative max-w-4xl  mx-auto mb-20"
        >
          <motion.img
            src={Kiaanecompic}
            alt="E-commerce Platform Interface"
            className="w-full h-auto rounded-lg shadow-2xl border-4 border-green-300"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          />
          <motion.div 
            className="absolute inset-0 bg-gradient-to-t from-green-900/70 to-transparent rounded-lg flex items-end justify-center pb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            <motion.a
              // href="ecom"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-green-800 px-6 py-3 rounded-full font-semibold hover:bg-green-100 transition-colors duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Launching Soon
            </motion.a>
          </motion.div>
        </motion.div>
        
        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-8 my-20"
        >
          <FeatureCard
            title="Farmer-Centric Interface"
            description="An easy-to-use platform tailored to meet the unique needs of farmers"
            icon={UserCheck}
          />
          <FeatureCard
            title="Extensive Product Catalog"
            description="Explore a diverse selection of top-quality agricultural products, all in one place"
            icon={ShoppingCart}
          />
          <FeatureCard
            title="Precision Agriculture Tools"
            description="Focuses on advanced tools to optimize farming operations and enhance productivity."
            icon={Tractor}
          />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default EcomSoftwarePromo;