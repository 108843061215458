import React, { useEffect, useState, useRef } from 'react';
import { Leaf } from 'lucide-react';
import whykiaan from '../Assets/Images/Mission_pic.png';
import faq from '../Assets/Images/faq-shape-2.png';
import plant from '../Assets/Images/plants.png';
import leaf from '../Assets/Images/leaf.png';

const Mission = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const listItems = [
    'Enabling Financial Inclusion and Accessibility.',
    'Enhancing Farm Productivity with Data-driven Insights.',
    'Optimizing Resource Management for Sustainable Returns.',
    'Facilitating Precision Agriculture',
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div className="bg-white p-4 md:p-8 font-sans overflow-hidden relative" id="whykiaan" ref={sectionRef}>
      <div className="flex flex-col lg:flex-row items-start">
        <div
          className={`w-full lg:w-1/2 pr-4 lg:pr-8 transform transition-all duration-1000 ease-out ${
            isVisible ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'
          }`}
        >
          <h2 className="text-green-700 text-lg font-semibold mb-2 flex items-center">
            <img src={leaf} alt="Leaf" className="w-5 h-5 mr-2" />
            WHY KIAANAGROW
            <img src={leaf} alt="Leaf" className="w-5 h-5 ml-2" />
          </h2>

          <h1 className="text-2xl lg:text-4xl font-bold text-gray-800 mb-4 lg:mb-6">
            We are on a dedicated mission to expand the reach and accessibility of data-driven agriculture for growers, enterprises, and financial institutions.
          </h1>

          <ul className="space-y-4 mb-8 font-serif">
            {listItems.map((item, index) => (
              <li
                key={index}
                className={`flex items-start transition-all duration-500 ${
                  isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-full'
                }`}
                style={{ transitionDelay: `${index * 800}ms` }}
              >
                <Leaf className="text-green-500 mr-2 mt-1 flex-shrink-0" size={20} />
                <span className="text-gray-700">{item}</span>
              </li>
            ))}
          </ul>

          <div
            className={`faq-one__shape absolute right-10 w-5 h-5 md:w-48 md:h-48 lg:w-56 lg:h-52 bg-no-repeat bg-right-bottom transition-all duration-1000 ease-out 
              md:right-0 md:-bottom-72 lg:left-4 lg:-bottom-52 
              ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'}`}
            style={{
              backgroundImage: `url(${plant})`,
              animation: isVisible ? 'treeMove 5s linear infinite' : 'none',
            }}
          />
        </div>

        <div
          className={`w-full lg:w-1/2 mt-8 lg:mt-0 transform transition-all duration-1000 ease-out relative ${
            isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
          }`}
        >
          <img src={whykiaan} alt="Agriculture" className="w-auto h-full rounded-lg shadow-lg" loading="lazy" />

          <div
            className={`faq-one_image_shape-two absolute -right-8 top-10 w-24 h-24 hidden lg:block 
              ${isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full'}`}
            style={{
              transition: 'opacity 1s ease-out, transform 1s ease-out',
              animation: isVisible ? 'faqMoveRotate 5s linear infinite' : 'none',
            }}
          >
            <img src={faq} alt="Decorative shape" className="w-full h-full object-cover rounded-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
